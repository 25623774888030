import '../../node_modules/jquery/dist/jquery.js'
import "../../node_modules/bootstrap/dist/js/bootstrap.js";
import '../../node_modules/owl.carousel/dist/owl.carousel';
import '../../node_modules/ilyabirman-likely/release/likely.js';
import '../../node_modules/intl-tel-input/build/js/intlTelInput';
import '../../node_modules/intl-tel-input/build/js/utils';

import './_components';





(function ($) {
  'use strict';

  // preload
  $(window).on('load', function () {
    $('.preloader').fadeOut();
  });

  var hero = $('#hero-carusel');
  hero.owlCarousel({
    items: 1,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true
  });
  $('.play').on('click', function () {
    owl.trigger('play.owl.autoplay', [5000])
  })
  $('.stop').on('click', function () {
    owl.trigger('stop.owl.autoplay')
  })


  $('.brand-carousel').owlCarousel({
    loop: true,
    margin: 10,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 1
      },
      500: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  })


  function termsChanged(termsCheckBox) {
    if (termsCheckBox.checked) {
      document.getElementById('sendMessageButton').disabled = false;
    } else {
      document.getElementById('sendMessageButton').disabled = true;
    }
  }

  $(".counter-area").waypoint(function (direction) {
    if (direction === "down") {
      $(".counter").each(function () {
        var $this = $(this);
        var counterNumber = $this.attr("data-count");
        $({
          couterInit: $this.text()
        }).animate({
          couterInit: counterNumber
        }, {
          duration: 5000,
          easing: 'linear',
          step: function () {
            $this.text(Math.ceil(this.couterInit));
          },
          complete: function () {
            $this.text(this.counterNumbercountNum);
          }
        })

      });

    }//end if

  }, {
    offset: "50%"
  });

  $(".item-serv").on({
    mouseenter: function () {
      $(this).css("background-color", "#ffb600");


    },
    mouseleave: function () {
      $(this).css("background-color", "#fff");
    }
  });

  $('.image-popup-no-margins').magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    closeBtnInside: true,
    fixedContentPos: true,
    mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
    image: {
      verticalFit: true,
      titleSrc: function (item) {
        return item.el.attr('title') + '<small>&copy;progress.ua</small>';
      }
    },
    zoom: {
      enabled: true,
      duration: 300 // don't foget to change the duration also in CSS
    }
  });


  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function (item) {
        return item.el.attr('title') + '<small>&copy;progress.ua</small>';
      }
    },
    tPrev: 'Попередній / Previous ',
    tNext: 'Наступний / Next',
    tCounter: '%curr% з (of) %total%'
  });

})(jQuery);



